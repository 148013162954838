import React, {useState} from 'react';
import './hansform.styles.css';

import  { TextField, MenuItem, Button, Modal, Box} from '@material-ui/core';
import {HansLogo, GuarrentySvg} from '../../SvgIcons';
import ThankuPopup from './thankupopup';



const currencies = [
    {
      value: 'Son',
    },
    {
      value: 'Daughter',
    },
    {
      value: 'Brother',
    },
    {
      value: 'Sister',
    },
    {
      value: 'Myself',
    },
    {
      value: 'Other',
    },
  ];

const UserInfo = () => {


    // const [currency, setCurrency] = useState();
    const [formData, setFormData]= useState({lookingFor:"Myself", phone:"", fullName:'', channel:'Facebook Leads Comment'})
    const [thankuPopupOpen, setThankuPopupOpen]= useState(false)



    const handleOptionChange = (event) => {
    //   setCurrency(event.target.value);
      setFormData({...formData, [event.target.name]: event.target.value});
    };



    const handleChange=(e)=>{
        setFormData({...formData, [e.target.name]: e.target.value})
    }


    const createNewLeads=(e)=>{
      return new Promise(async(resolve, reject)=>{
        try{
            const bodyData= new FormData();
            bodyData.append("user_phone", formData.phone)
            bodyData.append("for", formData.lookingFor)
            bodyData.append("cname", formData.fullName)
            bodyData.append("channel", formData.channel)

            let resp= await fetch(`https://partner.hansmatrimony.com/api/save-incomplete-leads`, {
              method:"POST",
            
              body: bodyData
            })

            let response= await resp.json();

            resolve(response)
        }
        catch(e){
          console.error("This is the console error", e)
        }
      })
    }


    const handleSubmit=async(e)=>{
        e.preventDefault();
        console.log("This is the handle submit", formData);
        await createNewLeads(e);
        setThankuPopupOpen(true)
    }

    console.log("values,", formData);

    const handleClose=(e) => {
        console.log("HandleClose")
    }

  return (
  

    <div className="hansFormMainContainer" style={{backgroundImage:`url(images/background.png)`, justifyContent: thankuPopupOpen ? 'space-between' : 'space-between', backgroundPosition:thankuPopupOpen ? '1px 132px' : '.1rem 7.5rem'}}>
    <div className="FirstDiv">
        <div className="imageAndTitleText">
            <div className="ImageContainer">
                <img src={`images/aparaMehta.png`} alt="apara mehta"></img>
            
            </div>

            <span className="bottomtextForImage">Apara Mehta</span>
        
        </div>
        <div className="dialogueContainer">
        
            <span className="dialogueText">Sirf Rishte nahi Jode dilo ko bhi!</span>
        </div>
    </div>

   {thankuPopupOpen ? 
    
    <ThankuPopup/>:
     (
<React.Fragment>
    <div className="SecondDiv">
        <div className="LogoAndCompanyName">
            <span className="LogoContainerSpan">
                <HansLogo className="hansSvgImage"/>
            </span>

            <span className="companyNameHans">Hans Matrimony</span>
        
        </div>

        <div className="svgAndTextContainer">
            <span className="guarrentyLogoContainerSpan">
                <GuarrentySvg/>
            </span>
            <span className="guarrentyText">50000+ matches made</span>
        </div>

       <div className="textStartWithEliteContainer">
            <span className="EliteMatchesTexts">Elite Matches from High-Class  Families</span>
            <span className="verifiedRisteyTexts">Verified Rishtey from your City</span>
      </div>
      </div>

    <form className="ThirdDiv" onSubmit={handleSubmit}>
        <div className="formLabelsAndFields">
            <label className='labelsForFormInputs'>Looking a Rishta for?</label>
     
            <TextField

            id="outlined-select-currency"
            select
            required={true}
            name="lookingFor"
            placeholder="eg: myself, son, sister"
            value={formData.lookingFor}
            onChange={handleOptionChange}
            variant="outlined"
            // style={{ width: '88%' }}
            className="TextFieldForuserInput"
            // helperText="Please select your currency"
            >
            {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                {option.value}
                </MenuItem>
            ))}
            </TextField>
        </div>

        <div className="formLabelsAndFields">
            <label className='labelsForFormInputs'>Phone Number</label>
         
                <TextField
                required={true}
                type="phone"
                id="outlined-phone"
                placeholder="Enter your phone number"
                value={formData.phone}
                name="phone"
                onChange={handleChange}
                variant="outlined"
                // style={{ width: '88%' }}
                className="TextFieldForuserInput"
                // helperText="Enter phone number"
                />

        </div>

        <div className="formLabelsAndFields">
            <label className='labelsForFormInputs'>Full Name</label>
          
                <TextField
                required={true}
                type="text"
                id="outlined-full-name"
                name="fullName"
                placeholder="Enter your full name"
                value={formData.fullName}
                onChange={handleChange}
                variant="outlined"
                // style={{ width: '88%' }}
                className="TextFieldForuserInput"
                // helperText="Enter your full name"
                />
        </div>
        <Button variant="contained" type="submit" className="submitButtonMaterial" >Submit</Button>
            </form>
            </React.Fragment>)}

</div>


    
  )
}

export default UserInfo;


  {/*// <Box

                // sx={{
                //     '& .MuiTextField-root': { m: 1, width: '40ch' },
                // }}
                // noValidate
                // autoComplete="off"
                // >


                        {/* </div>
        </Box>*/}
            // <div>*
            
            
            
            
              {/*<Modal
    aria-describedby='aria-description'
    aria-labelledby='aria-label'
    open={true}
    onClose={handleClose}
    >
    <Box className="formModal" style={{top:thankuPopupOpen ? 'calc(50% - 380px)' : 'calc(50% - 410px)'}}>
  */
 
 
 
     {/*</Box>
            </Modal>*/} }