import UserInfo from './components/hansform';

function App() {
  return (
    <div className="App">
        <UserInfo/>
    </div>
     
  );
}

export default App;
