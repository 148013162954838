import React from 'react';
import {CelebrationSvg,  CheckMarkSvg, ResponseInfo} from '../../../SvgIcons'
import './thankupopup.styles.css';
import {HansLogo, GuarrentySvg} from '../../../SvgIcons';

const ThankuPopup = () => {
  return (

    <div className="ThankuPopupStartContainer">
        
        <div className="secondDivUpperContainer">
                <div className="LogoAndCompanyName">
                <span className="LogoContainerSpan">
                    <HansLogo/>
                </span>

                <span className="companyNameHans">Hans Matrimony</span>
            
            </div>

            <div className="svgAndTextContainer">
                <span className="guarrentyLogoContainerSpan">
                    <GuarrentySvg/>
                </span>
                <span className="guarrentyText">50000+ matches made</span>
            </div>
        </div>
           

  
    

            <div className="celebrationAndDoneCombination">

                <span className="thankusvgContainerSpanCelebration">
                    <CelebrationSvg/>
                </span>
                <span className="spanForthankuSvgHavingDoneIcon">
                    <CheckMarkSvg/>
                </span>


                <div className="tankuTextSpanContainer">
                <span className="tankuTextSpan">
                    <span style={{fontWeight:'600'}}>Thanks, { " " }</span> 
                    <span style={{fontWeight:'500'}}>
                        you’re all set. { " " }
                    </span>
                </span>

                <span style={{fontWeight:'500'}}  className="tankuTextSpan">
                    Our representative will get in touch with you soon.
                </span>
            </div>
            </div>

         
            <span className="youCanCallAt">
            You can also call at{ " " }

            <span className="phoneNumberOfCompany">96-97-98-96-97</span>
            </span>

            <div className="thankuBottomContainer">
                <span className="svgForInfo">
                    <ResponseInfo/>
                </span>
    
                <span className="responseInfoSpan">
                    You successfully submitted your response
                </span>
            </div>


       
    </div>
  )
}

export default ThankuPopup;